import React, { FC } from "react"
import { PageProps } from "gatsby"
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core"
import FlightLandIcon from "@material-ui/icons/FlightLand"
import PeopleIcon from "@material-ui/icons/People"
import MusicNoteIcon from "@material-ui/icons/MusicNote"
import LocalHospitalIcon from "@material-ui/icons/LocalHospital"
import Layout from "../components/layout"

const Verein: FC<PageProps> = () => {
  function renderClubTitle() {
    return (
      <Typography variant="h4" paragraph>
        Verein
      </Typography>
    )
  }

  function renderGoals() {
    return (
      <>
        <Typography variant="h5">Ziele</Typography>
        <List>
          <ListItem>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText>Musik als Gemeinschaftserlebnis</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <LocalHospitalIcon />
            </ListItemIcon>
            <ListItemText>
              Nutzung des Mediums Musik für karitative Zwecke und soziales
              Engagement
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <MusicNoteIcon />
            </ListItemIcon>
            <ListItemText>Förderung von Nachwuchstalenten</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <FlightLandIcon />
            </ListItemIcon>
            <ListItemText>
              Zusammenarbeit mit ausländischen Partnern im Sinne der
              Völkerverständigung
            </ListItemText>
          </ListItem>
        </List>
      </>
    )
  }

  return (
    <Layout>
      {renderClubTitle()}
      {renderGoals()}
    </Layout>
  )
}

export default Verein
